import { css } from "@emotion/core"
import loader from "../images/loader.gif"
import arrow from "../images/arrows.png"
import pdficon from "../images/adobepdf.png"
export const pdfstyles = css`

	background-color:#434343;
  #sectionPage.pdfsection{
    background-color:#434343;
    margin-top:-35px;
    margin-bottom:0;
	overflow:hidden;
    
  }
  #MenuBar{
	  overflow:hidden;
	background-color:#434343;
	  position:absolute;
	  top: 0;
		color: #fff;
		width: 100%;
		z-index: 10;
		padding: 0 15px;
		height: 40px;
		line-height: 35px;
		#pageNumber
		{
			position: absolute;
			left: 48%;
			color: #fff;
			top: 0;
			input{
				width: 40px;
				text-align: center;
				border-radius: 2px;
				border: 1px #fff solid;
			}
		}
	  a{
		  color:#fff;
		  float:right;
		  position:relative;
		  padding-left:45px;
		  &:before{
			  content:"";
			  width:35px;
			  height:35px;
			  background-image:url(${pdficon});
			  background-repeat: no-repeat;
			  background-position: right top;
			  display:inline-block;
			  position:absolute;
			  left:0;
			  top:5px;
		  }
	  }
  }
  #footercontainer{
	background-color:#434343;
	position:absolute;
	bottom:0;
	width:100%;
	z-index:10;
	padding:0 15px;
	color: #fff;
	a{
		color: #fff;
	}
	.push-right {
		float: right;
		padding-right:5px;
	}
  }
  #pdfLoading{
	  color:#fff;
	  margin:0;
	  p{margin-left:-60px; color:#333;}
	  img{width:45px;margin-left:-15px;}
  }
  .magazine
  {
	display:none;
  }
  .magazine-viewport {
	  z-index:0;
  }
.magazine-viewport .container{
  background-color:transparent;
	position:absolute;
	top:50%;
	left:50%;
	width:300px;
	height:300px;
	margin:auto;
}

.magazine-viewport .magazine{
	width:922px;
	height:600px;
	left:-461px;
	top:-300px;
}

.magazine-viewport .page{
	width:461px;
	height:600px;
	background-color:white;
	background-repeat:no-repeat;
	background-size:100% 100%;
}

.magazine-viewport .zoomer .region{
	display:none;
}

.magazine-viewport .zoom-in .region{
	display:none;
}

.magazine .region{
	position:absolute;
	overflow:hidden;
	background:#0066FF;
	opacity:0.2;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	-ms-border-radius:10px;
	-o-border-radius:10px;
	border-radius:10px;
	cursor:pointer;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	filter: alpha(opacity=20);
}

.magazine .region:hover{
	opacity:0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity=50);
}

.magazine .region.zoom{
	opacity:0.01;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
	filter: alpha(opacity=1);
}

.magazine .region.zoom:hover{
	opacity:0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	filter: alpha(opacity=20);
}

.magazine .page{
	-webkit-box-shadow:0 0 5px rgba(0,0,0,0.8);
	-moz-box-shadow:0 0 5px rgba(0,0,0,0.8);
	-ms-box-shadow:0 0 5px rgba(0,0,0,0.8);
	-o-box-shadow:0 0 5px rgba(0,0,0,0.8);
	box-shadow:0 0 5px rgba(0,0,0,0.8);
}

.magazine-viewport .page img{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin:0;
}

.magazine .even .gradient{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;

	background:-webkit-gradient(linear, left top, right top, color-stop(0.95, rgba(0,0,0,0)), color-stop(1, rgba(0,0,0,0.2)));
	background-image:-webkit-linear-gradient(left, rgba(0,0,0,0) 95%, rgba(0,0,0,0.2) 100%);
	background-image:-moz-linear-gradient(left, rgba(0,0,0,0) 95%, rgba(0,0,0,0.2) 100%);
	background-image:-ms-linear-gradient(left, rgba(0,0,0,0) 95%, rgba(0,0,0,0.2) 100%);
	background-image:-o-linear-gradient(left, rgba(0,0,0,0) 95%, rgba(0,0,0,0.2) 100%);
	background-image:linear-gradient(left, rgba(0,0,0,0) 95%, rgba(0,0,0,0.2) 100%);
}

.magazine .odd .gradient{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;

	background:-webkit-gradient(linear, right top, left top, color-stop(0.95, rgba(0,0,0,0)), color-stop(1, rgba(0,0,0,0.15)));
	background-image:-webkit-linear-gradient(right, rgba(0,0,0,0) 95%, rgba(0,0,0,0.15) 100%);
	background-image:-moz-linear-gradient(right, rgba(0,0,0,0) 95%, rgba(0,0,0,0.15) 100%);
	background-image:-ms-linear-gradient(right, rgba(0,0,0,0) 95%, rgba(0,0,0,0.15) 100%);
	background-image:-o-linear-gradient(right, rgba(0,0,0,0) 95%, rgba(0,0,0,0.15) 100%);
	background-image:linear-gradient(right, rgba(0,0,0,0) 95%, rgba(0,0,0,0.15) 100%);
}

.magazine-viewport .zoom-in .even .gradient,
.magazine-viewport .zoom-in .odd .gradient{

	display:none;

}

.magazine-viewport .loader{
	background-image:url(${loader});
	width:22px;
	height:22px;
	position:absolute;
	top:280px;
	left:219px;
}

.magazine-viewport .shadow{
	-webkit-transition: -webkit-box-shadow 0.5s;
	-moz-transition: -moz-box-shadow 0.5s;
	-o-transition: -webkit-box-shadow 0.5s;
	-ms-transition: -ms-box-shadow 0.5s;

	-webkit-box-shadow:0 0 20px #ccc;
	-moz-box-shadow:0 0 20px #ccc;
	-o-box-shadow:0 0 20px #ccc;
	-ms-box-shadow:0 0 20px #ccc;
	box-shadow:0 0 20px #ccc;
	display:none;
}

.magazine-viewport .next-button,
.magazine-viewport .previous-button{
	width:40px;
	height:841px;
	position:absolute;
	top:0;
}

.magazine-viewport .next-button{
	right:-40px;
	-webkit-border-radius:0 15px 15px 0;
	-moz-border-radius:0 15px 15px 0;
	-ms-border-radius:0 15px 15px 0;
	-o-border-radius:0 15px 15px 0;
	border-radius:0 15px 15px 0;
}

.magazine-viewport .previous-button{
	left:-40px;
	-webkit-border-radius:15px 0 0 15px;
	-moz-border-radius:15px 0 0 15px;
	-ms-border-radius:15px 0 0 15px;
	-o-border-radius:15px 0 0 15px;
	border-radius:15px 0 0 15px;
}

.magazine-viewport .previous-button-hover,
.magazine-viewport .next-button-hover{
	background-color:rgba(0,0,0, 0.2);
}
.magazine-viewport .previous-button,
.magazine-viewport .previous-button-hover,
.magazine-viewport .previous-button-down{
	background-image:url(${arrow});
	background-position:5px 380px;
	background-repeat:no-repeat;
}

.magazine-viewport .previous-button-down,
.magazine-viewport .next-button-down{
	background-color:rgba(0,0,0, 0.4);
}
.magazine-viewport .next-button,
.magazine-viewport .next-button-hover,
.magazine-viewport .next-button-down{
	background-image:url(${arrow});
	background-position:-30px 380px;
	background-repeat:no-repeat;
}

.magazine-viewport .zoom-in .next-button,
.magazine-viewport .zoom-in .previous-button{
	display:none;
}

.animated{
	-webkit-transition:margin-left 0.5s;
	-moz-transition:margin-left 0.5s;
	-ms-transition:margin-left 0.5s;
	-o-transition:margin-left 0.5s;
	transition:margin-left 0.5s;
}

.thumbnails{
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	height:140px;
	z-index:1;
}

.thumbnails > div{
	width:1050px;
	height:100px;
	margin:20px auto;
}

.thumbnails ul{
	margin:0;
	padding:0;
	text-align:center;
	-webkit-transform:scale3d(0.5, 0.5, 1);
	-moz-transform:scale3d(0.5, 0.5, 1);
	-o-transform:scale3d(0.5, 0.5, 1);
	-ms-transform:scale3d(0.5, 0.5, 1);
	transform:scale3d(0.5, 0.5, 1);
	-webkit-transition:-webkit-transform ease-in-out 100ms;
	-moz-transition:-moz-transform ease-in-out 100ms;
	-ms-transition:-ms-transform ease-in-out 100ms;
	-o-transition:-o-transform ease-in-out 100ms;
	transition:transform ease-in-out 100ms;
}

.thumbanils-touch ul{
	-webkit-transform:none;
	-moz-transform:none;
	-o-transform:none;
	-ms-transform:none;
	transform:none;
}

.thumbnails-hover ul{
	-webkit-transform:scale3d(0.6, 0.6, 1);
	-moz-transform:scale3d(0.6, 0.6, 1);
	-o-transform:scale3d(0.6, 0.6, 1);
	-ms-transform:scale3d(0.6, 0.6, 1);
	transform:scale3d(0.6, 0.6, 1);
}

.thumbnails li{
	list-style:none;
	display:inline-block;
	margin:0 5px;
	-webkit-box-shadow:0 0 10px #ccc;
	-moz-box-shadow:0 0 10px #ccc;
	-ms-box-shadow:0 0 10px #ccc;
	-o-box-shadow:0 0 10px #ccc;
	box-shadow:0 0 10px  #ccc;
	-webkit-transition:-webkit-transform 60ms;
	-moz-transition:-webkit-transform 60ms;
	-o-transition:-webkit-transform 60ms;
	-ms-transition:-webkit-transform 60ms;
	transition:-webkit-transform 60ms;
}

.thumbnails li span{
	display:none;
}

.thumbnails .current{
	-webkit-box-shadow:0 0 10px red;
	-moz-box-shadow:0 0 10px red;
	-ms-box-shadow:0 0 10px red;
	-o-box-shadow:0 0 10px red;
	box-shadow:0 0 10px red;
}

.thumbnails .thumb-hover{
	-webkit-transform:scale3d(1.3, 1.3, 1);
	-moz-transform:scale3d(1.3, 1.3, 1);
	-o-transform:scale3d(1.3, 1.3, 1);
	-ms-transform:scale3d(1.3, 1.3, 1);
	transform:scale3d(1.3, 1.3, 1);

	-webkit-box-shadow:0 0 10px #666;
	-moz-box-shadow:0 0 10px #666;
	-ms-box-shadow:0 0 10px #666;
	-o-box-shadow:0 0 10px #666;
	box-shadow:0 0 10px #666;
}

.thumbanils-touch .thumb-hover{
	-webkit-transform:none;
	-moz-transform:none;
	-o-transform:none;
	-ms-transform:none;
	transform:none;
}

.thumbnails .thumb-hover span{
	position:absolute;
	bottom:-30px;
	left:0;
	z-index:2;
	width:100%;
	height:30px;
	font:bold 15px arial;
	line-height:30px;
	color:#666;
	display:block;
	cursor:default;
}

.thumbnails img{
	float:left;
}

.esc{
	position: absolute;
	top:10px;
	left:0;
	width:100%;
	height:40px;
	z-index:10000;
}

.esc > div{
	width:140px;
	height:30px;
	margin:auto;
	background:rgba(0,0,0,0.5);
	text-align:center;
	font:12px arial;
	line-height:30px;
	color:white;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	-ms-border-radius:10px;
	-o-border-radius:10px;
	border-radius:10px;
}

.made{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	font: 14px arial;
	line-height:40px;
	color:#666;
	text-align:center;
	z-index:10;
}

.get-now{
	text-decoration:none;
	margin:0;
	padding:5px 20px;
	color:white;
	text-shadow:0 -1px 0 #3D561F;
	border:1px solid #4D682B;
	background-color:#657948;

	background:-webkit-gradient(linear, left top, left bottom, color-stop(0, #82A353),  color-stop(0.5, #62843C), color-stop(0.51,  #5B7E32), color-stop(1,  #476928));
	background:-webkit-linear-gradient(top, #82A353 0%, #62843C 50%, #5B7E32 51%, #476928 100%);
	background:-moz-linear-gradient(top, #82A353 0%, #62843C 50%, #5B7E32 51%, #476928 100%);
	background:-ms-linear-gradient(top, #82A353 0%, #62843C 50%, #5B7E32 51%, #476928 100%);
	background:-o-linear-gradient(top, #82A353 0%, #62843C 50%, #5B7E32 51%, #476928 100%);
	background:linear-gradient(top, #82A353 0%, #62843C 50%, #5B7E32 51%, #476928 100%);

	-webkit-box-shadow:inset 0 1px 1px #9ECF7A, inset 0 -1px 1px #355828;
	-moz-box-shadow:inset 0 1px 1px #9ECF7A, inset 0 -1px 1px #355828;
	-o-box-shadow:inset 0 1px 1px #9ECF7A, inset 0 -1px 1px #355828;
	-ms-box-shadow:inset 0 1px 1px #9ECF7A, inset 0 -1px 1px #355828;
	box-shadow:inset 0 1px 1px #9ECF7A, inset 0 -1px 1px #355828;

	-webkit-border-radius:20px;
	-moz-border-radius:20px;
	-ms-border-radius:20px;
	-o-border-radius:20px;
	border-radius:20px;
}


.get-now:hover{
	border:1px solid #3F5228;
	text-decoration:none;

	background:-webkit-gradient(linear, left top, left bottom, color-stop(0, #688939), color-stop(1,  #5B6D40));
	background:-webkit-linear-gradient(top, #688939, #5B6D40);
	background:-moz-linear-gradient(top, #688939, #5B6D40);
	background:-ms-linear-gradient(top, #688939, #5B6D40);
	background:-o-linear-gradient(top, #688939, #5B6D40);
	background:linear-gradient(top, #688939, #5B6D40);
}

.get-now:active{
	border:1px solid #243017;

	background:-webkit-gradient(linear, left top, left bottom, color-stop(0, #577330), color-stop(1,  #4C5B35));
	background:-webkit-linear-gradient(top, #577330, #4C5B35);
	background:-moz-linear-gradient(top, #577330, #4C5B35);
	background:-ms-linear-gradient(top, #577330, #4C5B35);
	background:-o-linear-gradient(top, #577330, #4C5B35);
	background:linear-gradient(top, #577330, #4C5B35);
}

/slider/
#slider{
	position: relative;
}
.ui-slider {
	position: relative;
	text-align: left;
}
.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 1.2em;
	height: 1.2em;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}

.ui-slider-horizontal {
	height: .8em;
}
.ui-slider-horizontal .ui-slider-handle {
	top: -.3em;
	margin-left: -.6em;
}
.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: .8em;
	height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}
.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
	border-top-left-radius: 3px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
	border-top-right-radius: 3px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
	border-bottom-left-radius: 3px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
	border-bottom-right-radius: 3px;
}
.ui-widget-header {
	border: 1px solid #dddddd;
	background: #e9e9e9;
	color: #333333;
	font-weight: bold;
}
/* Component containers
----------------------------------*/
.ui-widget {
	font-family: Arial,Helvetica,sans-serif;
	font-size: 1em;
}
.ui-widget .ui-widget {
	font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-family: Arial,Helvetica,sans-serif;
	font-size: 1em;
}
.ui-widget.ui-widget-content {
	border: 1px solid #c5c5c5;
}
.ui-widget-content {
	border: 1px solid #dddddd;
	background: rgba(237, 237, 237, 0.5);
	color: #333333;
}
.ui-widget-content a {
	color: #333333;
}
.ui-widget-header {
	border: 1px solid #dddddd;
	background: #e9e9e9;
	color: #333333;
	font-weight: bold;
}
.ui-widget-header a {
	color: #333333;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,

/* We use html here because we need a greater specificity to make sure disabled
works properly when clicked or hovered */
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
	border: 1px solid #c5c5c5;
	background: #f6f6f6;
	font-weight: normal;
	color: #454545;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
	color: #454545;
	text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
	border: 1px solid #cccccc;
	background: #ededed;
	font-weight: normal;
	color: #2b2b2b;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
	color: #2b2b2b;
	text-decoration: none;
}

.ui-visual-focus {
	box-shadow: 0 0 3px 1px rgb(94, 158, 214);
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
	border: 1px solid #003eff;
	background:rgb(70, 162, 217);
	font-weight: normal;
	color: #ffffff;
}
.ui-icon-background,
.ui-state-active .ui-icon-background {
	border: #003eff;
	background-color: #ffffff;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
	color: #ffffff;
	text-decoration: none;
}
#Pageslider{height: 20px;}
#slider{margin-top:5px;}
@media (max-width: 767px){

	#pageNumber{display:none;}
}
  `
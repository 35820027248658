import React from 'react';
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PDFViewer from "../components/pdfpages"
import { layoutStyles } from '../style/layoutStyles'
import SEO from "../components/seo"
import {pdfstyles} from '../style/pdfViewer'
import useScript from '../components/useScript';
import {Link} from 'gatsby'

export default  ({ data, pageContext }) => {
  const post = data.wpgraphql.publication
  var keywords = [];
  const currentYear = new Date().getFullYear()
  useScript('https://cdn.ports.je/web/turnjs/jquery.min.js');
  useScript('https://cdn.ports.je/web/turnjs/jquery-ui.js');
  useScript('https://cdn.ports.je/web/turnjs/hash.js');
  useScript('https://cdn.ports.je/web/turnjs/magazine.js');
  useScript('https://cdn.ports.je/web/turnjs/turn.min.js');
  useScript('https://cdn.ports.je/web/turnjs/zoom.min.js'); 
  useScript('https://cdn.ports.je/web/turnjs/pojflipbook.js');
  
 if (post.keywords.nodes != null)
   {
     post.keywords.nodes.forEach(node => { keywords.push(node.name);});
  }

  return (
    <Layout>
        <SEO title={post.title} keywords={keywords} /> 
        <div css={layoutStyles} id="content">
            <div css={pdfstyles}>
              <div id="sectionPage"  className="col-1-1 pdfsection">
              <div id="MenuBar">
              <div className="grid"><div className="col-1-1"><span>{post.title}</span><Link to={post.PublicationsFields.pdfUrl.mediaItemUrl}> Download PDF</Link><div id="pageNumber"></div></div></div></div>
                <div className="magazine-viewport" >
                  <div className="container">
                    <PDFViewer title={post.title} url={post.PublicationsFields.pdfUrl.mediaItemUrl} />
                  </div>
                </div>
               </div>
               <div id="footercontainer"><div className="grid"><div id="Pageslider" className="col-1-1"></div><div className="copyright col-1-1">
               {process.env.GATSBY_SITE_MODE==="AIRPORT" ?
          <span className="push-left">&copy; Jersey Airport {currentYear}</span>
        :
          <span className="push-left">&copy;  Ports of Jersey {currentYear}</span>
        }  
                
               <a className="push-right" href="/terms-and-conditions">Terms and conditions</a><a className="push-right" href="/privacypolicy">Privacy policy </a></div> </div></div>
            </div>
            
      </div>
          

    </Layout>
  )
}


export const query = graphql`
  query GET_PDFS($id: ID!){
      wpgraphql {
        publication(id: $id) {
          title
          slug
          keywords {
            nodes {
              name
            }
          }
          PublicationsFields {
            pdfUrl {
              mediaItemUrl
              sizes
            }
            sectors
          }
      }
    }
  }
`
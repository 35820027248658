import React, {  useRef,useState ,useEffect} from 'react';
import pdfjsLib from '@bundled-es-modules/pdfjs-dist/build/pdf';
import loader from "../images/loaderwhite.gif"
import {isMobile} from 'react-device-detect';

export default function PdfViewer(props){

    pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/es5/build/pdf.worker.js";
    const canvasRef = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const url = props.url;
   
    useEffect(() => {
    const fetchPdf = async () => {
      const loadingTask = pdfjsLib.getDocument(url);

      const pdfDoc = await loadingTask.promise;

      var totalPage = pdfDoc._pdfInfo.numPages;
      for (let i = 1; i <= totalPage; i++) {
          
          const page = await pdfDoc.getPage(i);
          
          let viewscale=1.5;
          if (isMobile)
          viewscale=1
          const viewport = page.getViewport({ scale:viewscale});
          const canvas = document.createElement('canvas')  
          const ctx = canvas.getContext("2d");
          canvas.width = viewport.width;
          canvas.height= viewport.height;

          page.render({
          canvasContext: ctx,
          viewport: viewport,

          });

          canvasRef.current.appendChild(canvas)  
          
      }
      setLoaded(true);
    };

    fetchPdf();
  }, [url]);
  
    return (
        <>
        <div id="pdfLoading"> {loaded ? (<span>Ready</span> ):(<div> <img src={loader} alt="loading..."/><p>Loading... Please wait.</p></div>)}</div>
        <div className="magazine" ref={canvasRef}>
            
             <div ignore="1" className="next-button"></div>
             <div ignore="1" className="previous-button"></div>
     
        </div>
</>

    );
    }
